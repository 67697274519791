import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web'
import './Header.css';

const Header = (props) => {
  const { keycloak, initialized } = useKeycloak();

  const [expanded, setExpanded] = useState();

  const handleMenu = () => {
    expanded ? setExpanded(false) : setExpanded(true);
  }

  return (
    <nav className="sdds-navbar">
      <div className={!expanded ? "sdds-navbar-overlay" : "sdds-navbar-overlay expanded"}></div>

      <button id="menu-mobile" onClick={handleMenu} className={!expanded ? "sdds-navbar-icon-button sdds-navbar-side-menu-drawer" : "sdds-navbar-icon-button sdds-navbar-side-menu-drawer expanded"}>
        <span className="sdds-icon-drawer"></span>
      </button>

      <div className="sdds-navbar-application-brand">CIAM Demo App</div>

      <div id="side-menu" className={!expanded ? "sdds-navbar-collapsible" : "sdds-navbar-collapsible expanded"}>
        <ul className="sdds-navbar-menu-list">
          {initialized ? !keycloak.authenticated
            ?
            <li className="sdds-navbar-menu-item" onClick={() => { keycloak.login({ scope: props.scopeParams }) }}>
              <span className="sdds-navbar-menu-item-link" >Log in</span>
            </li>
            :
            <li className="sdds-navbar-menu-item" onClick={() => { keycloak.logout() }}>
              <span className="sdds-navbar-menu-item-link">Log out</span>
            </li> : ""}

          {initialized && keycloak.authenticated ?
            <li className="sdds-navbar-menu-item" onClick={() => keycloak.updateToken(-1)}>
              <span className="sdds-navbar-menu-item-link">Force refresh token</span>
            </li> : ""}

          {initialized && keycloak.authenticated ?
            <li className="sdds-navbar-menu-item" onClick={() => keycloak.accountManagement()}>
              <span className="sdds-navbar-menu-item-link">View account</span>
            </li> : ""}
        </ul>
      </div>

      <div className="sdds-navbar-scania-brand" to="/"> </div>
    </nav >
  );
}

export default Header;