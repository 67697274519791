import { useKeycloak } from '@react-keycloak/web'
import React from 'react';

const ScopeField = (props) => {
    const { keycloak, initialized } = useKeycloak();

    function handleChange(e) {
        props.onChange(e.target.value);
    }

    if (!initialized || keycloak.authenticated)
        return null;

    return (
        <div className="component-wrapper">
            <div className="sdds-card" >

                <div className="sdds-card-header">
                    <h6 className="sdds-card-headline" style={{ marginBottom: "1em" }}>
                        Keycloak Scope
                    </h6>
                    <h6 className="sdds-card-sub-headline">Examples: openid email department profile mfa</h6>
                </div>

                <div className="sdds-card-body" >
                    <sdds-textfield onInput={handleChange} type="text" placeholder="Scope parameters"></sdds-textfield>
                </div>
            </div>
        </div >

    );
}

export default ScopeField;