import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { defineCustomElements, addTheme } from '@scania/components';
import { theme as scania } from '@scania/theme-light';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

defineCustomElements();
addTheme(scania)