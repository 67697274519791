import React, { useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/header/Header';
import Show from './components/show/Show';
import keycloak from './keycloak'


function App() {

  //Enable React Hooks to update state when certain events are fired
  const [exp, setToken] = useState('');
  const [scopeParams, setScopeParams] = useState('');

  //Keycloak JS Init - Goes to KeycloakProvider. You probably want to disable "enableLogging" for production.
  const keycloakConfig = { flow: "standard", enableLogging: true };

  const sendToast = (msg) => {
    toast.dark(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }

  //Runs everytime a Keycloak event is fired
  const onEvent = (event, error) => {
    if (typeof error !== undefined) {
      if (event === "onAuthRefreshSuccess") {
        setToken(keycloak.refreshTokenParsed.exp);
        console.log("Refreshed token. Exp has changed from " + exp + " to " + keycloak.refreshTokenParsed.exp);

        sendToast(<span>Keycloak&nbsp;&nbsp;<b>Refresh Token</b><br /><br /> Expires: <b>{keycloak.refreshTokenParsed.exp}</b><br />Issued at: <b>{keycloak.refreshTokenParsed.iat}</b></span>);
      }

      sendToast(<span>Keycloak&nbsp;&nbsp;<b>{event ? event.substring(2) : ""} {error ? error : ""}</b></span>);
    }
  };

  const scopeUpdate = (params) => {
    console.log("New scope params: " + params);
    setScopeParams(params);
  }

  //If you need tokens in code
  // const onTokens = tokens => {
  //   console.log(tokens.token);
  // };

  return (
    <ReactKeycloakProvider initOptions={keycloakConfig} onEvent={onEvent} /*onTokens={onTokens}*/ authClient={keycloak}>
      <sdds-theme></sdds-theme>
      <div className="App">
        <Header scopeParams={scopeParams} />
        <div className="sdds-container content-wrapper">
          <Show scopeUpdate={scopeUpdate} />
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      </div>
    </ReactKeycloakProvider>
  );
}

export default App;