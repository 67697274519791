import { useKeycloak } from '@react-keycloak/web'
import ScopeField from '../scopeField/ScopeField';

function Show(props) {
  const { keycloak, initialized } = useKeycloak();

  const scopeUpdate = (params) => {
    props.scopeUpdate(params);
  }

  return (
    <div className="sdds-row">
      <div className=" sdds-col-sm-4 sdds-col-md-8 sdds-col-lg-16 sdds-col-xlg-16">

        <p className="lead-head sdds-paragraph-01">
          Keycloak token info
        </p>

        <p className="lead-head sdds-paragraph-02">
          {`User is ${!keycloak.authenticated ? 'not ' : ''} authenticated${!keycloak.authenticated ? ', please log in' : ''}`}
        </p>

      </div>


      <div className=" sdds-col-sm-4 sdds-col-md-8 sdds-col-lg-8 sdds-col-xlg-8">

        {!!keycloak.idTokenParsed ?
          <div className="component-wrapper">
            <div className="sdds-card ">

              <div className="sdds-card-header">
                <h6 className="sdds-card-headline">ID Token</h6>
              </div>

              <div className="sdds-card-body">
                <pre>{JSON.stringify(keycloak.idTokenParsed, null, 2)}</pre>
              </div>

            </div>
          </div> : ""}


        {!!keycloak.refreshTokenParsed ?
          <div className="component-wrapper">
            <div className="sdds-card ">

              <div className="sdds-card-header">
                <h6 className="sdds-card-headline">Refresh Token</h6>
              </div>

              <div className="sdds-card-body">
                <pre>{JSON.stringify(keycloak.refreshTokenParsed, null, 2)}</pre>
              </div>

            </div>
          </div> : ""}


        <div className="component-wrapper">
          <div className="sdds-card ">

            <div className="sdds-card-header">
              <h6 className="sdds-card-headline">Keycloak Properties</h6>
            </div>

            <div className="sdds-card-body">
              {keycloak.flow ? <pre>Flow: {JSON.stringify(keycloak.flow)}</pre> : ""}
              {keycloak.timeSkew ? <pre>TimeSkew: {JSON.stringify(keycloak.timeSkew)}</pre> : ""}
              {keycloak.resourceAccess ? <pre>ResourceAccess: {JSON.stringify(keycloak.resourceAccess, null, 2)}</pre> : ""}
              {keycloak.subject ? <pre>Subject: {JSON.stringify(keycloak.subject)}</pre> : ""}
              {keycloak.authenticated ? <pre>Authenticated: {JSON.stringify(keycloak.authenticated)}</pre> : ""}
              {initialized ? <pre>Initialized: {JSON.stringify(initialized)}</pre> : ""}
              {keycloak.clientId && keycloak.authenticated ? <pre>Client ID: {JSON.stringify(keycloak.clientId)}</pre> : ""}
              {keycloak.authServerUrl ? <pre>authServerUrl: {JSON.stringify(keycloak.authServerUrl)}</pre> : ""}
            </div>

          </div>
        </div>

        <ScopeField onChange={scopeUpdate} />

      </div>

      <div className=" sdds-col-sm-4 sdds-col-md-8 sdds-col-lg-8 sdds-col-xlg-8">

        {!!keycloak.tokenParsed ?
          <div className="component-wrapper">
            <div className="sdds-card ">

              <div className="sdds-card-header">
                <h6 className="sdds-card-headline">Token</h6>
              </div>

              <div className="sdds-card-body">
                <pre> {JSON.stringify(keycloak.tokenParsed, null, 2)}</pre>
              </div>
            </div>

          </div> : ""}

      </div>

    </div>

  );
}

export default Show;